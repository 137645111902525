import firebase from 'firebase/app'

const createCheckoutSession = async (
  orderId: string
): Promise<{ url: string }> => {
  const createCheckoutSessionFunction = firebase
    .app()
    .functions('europe-west3')
    .httpsCallable('createCheckoutSession')
  const result = await createCheckoutSessionFunction({
    order_id: orderId
  })

  return {
    url: result.data.url
  }
}

export { createCheckoutSession }
