





















































































































































































































import { Component } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
import Alert from '@/components/Alert.vue'
import Button from '@/components/Button.vue'
import Dialog from '@/components/Dialog.vue'
import Content from '@/components/Content.vue'
import Loading from '@/components/Loading.vue'
import { createCheckoutSession } from '@/services/PaymentService'
import { FarmPeriod } from '@/models/FarmPeriod'
import CartMixin from '@/mixins/CartMixin.vue'
import { mixins } from 'vue-class-component'
import { createOrder } from '@/services/OrderService'

@Component({
  components: {
    Header,
    Alert,
    Button,
    Dialog,
    Content,
    Loading
  }
})
export default class Order extends mixins(CartMixin) {
  orderId: string = null
  ordering: boolean = false
  orderState: 'processing' | 'failed' = 'processing'
  orderError: string = ''
  farmPeriods: { [id: string]: FarmPeriod[] } = {}

  success: boolean = false
  error: boolean = false

  async created() {
    this.error = !!this.$route.query.fehler
    this.success = !!this.$route.query.erfolg

    if (this.success) {
      this.mainStore.clearCart()
      return
    }

    if (!this.error && this.orderItems.length === 0) {
      this.$router.push({ name: 'home' })
    }
  }

  async order() {
    try {
      this.ordering = true
      this.orderState = 'processing'

      const order = await createOrder({
        items: this.orderItems
      })

      this.orderId = order.orderId

      if (this.total <= 0) {
        this.completeOrder()
      } else {
        const session = await createCheckoutSession(this.orderId)

        window.location.href = session.url
      }
    } catch (ex) {
      this.orderState = 'failed'
      this.orderError = ex.message
    }
  }

  completeOrder() {
    this.mainStore.clearCart()
    this.$router.push({
      name: 'order-detail',
      params: { orderId: this.orderId },
      query: { type: 'confirmation' }
    })
  }

  goToDashboard() {
    this.ordering = false
    this.$router.push({ name: 'home' })
  }
}
